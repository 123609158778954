<template>
    <div style="position: relative; min-height: 449px;">
        <textarea v-model.lazy="content" ref="editor" :id="$data._id" :name="name" :rows="rows" cols="cols" @change="input" style="width: 100%; display: none">{{ value }}</textarea>
        <naver-smarteditor-images v-if="usesCustomImageUploader" v-model="content" @input="input">
            <template #activator="{attrs,on}">
                <v-btn absolute top right min-width="50" height="30" tile color="transparent" class="mt-n4 mr-n4" v-bind="attrs" v-on="on" />
            </template>
        </naver-smarteditor-images>
    </div>
</template>

<script>
import NaverSmarteditorImages from "./naver-smarteditor-images.vue";
let uuid = 0;
export default {
    components: { NaverSmarteditorImages },
    props: {
        id: { type: String, default: null },
        name: { type: String, default: undefined },
        rows: { type: String, default: "10" },
        cols: { type: String, default: "100" },
        value: { type: String, default: "" },
        bSkipXssFilter: { type: Boolean, default: false },
        usesCustomImageUploader: { type: Boolean, default: false },
    },
    data() {
        return {
            _id: this.$props.id || `ir${uuid}`,
            oEditors: [],
            content: "",
        };
    },
    beforeCreate() {
        uuid += 1;
    },
    mounted() {
        const { bSkipXssFilter } = this;

        var component = this;
        this.content = this.value;

        nhn.husky.EZCreator.createInIFrame({
            oAppRef: this.oEditors,
            elPlaceHolder: this.$data._id,
            sSkinURI: "/js/smarteditor2/SmartEditor2Skin.html",
            fCreator: "createSEditor2",
            fOnAppLoad: () => {
                //기존 저장된 내용의 text 내용을 에디터상에 뿌려주고자 할때 사용
                try {
                    // this.oEditors.getById[this._id].exec("PASTE_HTML", [this.value]);
                    var contentElement = component.oEditors.getById[component.$data._id].elEditingAreaContainer.children[0].contentWindow.document.getElementsByClassName("se2_inputarea")[0];
                    if (contentElement) {
                        contentElement.addEventListener("focusout", function() {
                            component.oEditors.getById[component.$data._id].exec("UPDATE_CONTENTS_FIELD", []);

                            var event = document.createEvent("HTMLEvents");
                            event.initEvent("change", false, true);
                            component.$refs.editor.dispatchEvent(event);
                        });
                    }
                } catch (error) {
                    console.error("[SmartEditor]-fOnAppLoad");
                    console.error(error);
                }
            },
            htParams: { bSkipXssFilter },
        });
    },
    methods: {
        input() {
            console.log("input");
            this.$emit("input", this.content);
        },
    },
    watch: {
        value() {
            this.content = this.value;

            // HTML 업데이트
            if (this.bSkipXssFilter) {
                this.oEditors?.getById?.[this?.$data?._id]?.exec("SET_IR", [""]);
                this.oEditors?.getById?.[this?.$data?._id]?.exec("PASTE_HTML", [this.content]);
            }
        },
    },
};
</script>
