var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative",
      "min-height": "449px"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.content,
      expression: "content",
      modifiers: {
        "lazy": true
      }
    }],
    ref: "editor",
    staticStyle: {
      "width": "100%",
      "display": "none"
    },
    attrs: {
      "id": _vm.$data._id,
      "name": _vm.name,
      "rows": _vm.rows,
      "cols": "cols"
    },
    domProps: {
      "value": _vm.content
    },
    on: {
      "change": [function ($event) {
        _vm.content = $event.target.value;
      }, _vm.input]
    }
  }, [_vm._v(_vm._s(_vm.value))]), _vm.usesCustomImageUploader ? _c('naver-smarteditor-images', {
    on: {
      "input": _vm.input
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mt-n4 mr-n4",
          attrs: {
            "absolute": "",
            "top": "",
            "right": "",
            "min-width": "50",
            "height": "30",
            "tile": "",
            "color": "transparent"
          }
        }, 'v-btn', attrs, false), on))];
      }
    }], null, false, 4246998646),
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }