var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "720",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(props) {
        return [_vm._t("activator", null, null, props)];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("이미지업로드")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-3"
  }, [_c('images-input', {
    model: {
      value: _vm.images,
      callback: function callback($$v) {
        _vm.images = $$v;
      },
      expression: "images"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey darken-1"
    },
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v(" 취소 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.upload
    }
  }, [_vm._v(" 업로드 ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }