<template>
    <v-dialog v-model="shows" width="720" persistent>
        <template #activator="props">
            <slot name="activator" v-bind="props" />
        </template>
        <v-card>
            <v-card-title>이미지업로드</v-card-title>
            <v-divider />
            <v-card-text class="py-3">
                <images-input v-model="images" />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn @click="shows = false" outlined color="grey darken-1"> 취소 </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="upload" color="primary"> 업로드 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ImagesInput from "@/components/console/dumb/images-input.vue";
import api from "@/api";
export default {
    components: { ImagesInput },
    props: {
        value: { type: String, default: "" },
    },
    data: () => ({
        shows: false,
        images: [],
    }),
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        init() {
            this.images = [];
        },
        async upload() {
            const items = await Promise.all(this.images.map(async (file) => await api.editor.postPhoto({ file })));
            const content =
                this.value +
                items.reduce((tags, item) => {
                    const src = item
                        ?.match(/&sFileURL=[^&=]*/)?.[0]
                        ?.split?.("=")
                        ?.pop?.();
                    if (src) return tags + `<img src="${src}" title="${item.split("/").pop()}" ><br style="clear:both;">`;
                    else return tags;
                }, "");
            this.$emit("input", content);
            this.shows = false;
        },
    },
};
</script>

<style></style>
